






































import { Component, Prop, Vue } from 'vue-property-decorator';
const embedUrlBase = 'https://www.youtube.com/embed';
const thumbUrlBase = 'https://i.ytimg.com/vi';
@Component
export default class YoutubeEmbedLite extends Vue {
  @Prop({ default: 9 / 16 }) aspect: number;
  @Prop() vid: string;
  @Prop({ default: 'mq' }) thumbQuality: string;
  @Prop() params: { [key: string]: any };

  isClicked = false;

  get contentStyle() {
    return {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
    };
  }

  get wrapperStyle() {
    return {
      width: '100%',
      height: '0px',
      position: 'relative',
      'padding-bottom': `${this.aspect * 100}%`,
    };
  }
  get src() {
    const autoplayParams = { ...this.params, autoplay: 1 } as { [key: string]: any };
    const query = Object.keys(autoplayParams)
      .reduce<string[]>((prev, curr) => [...prev, [curr, autoplayParams[curr]].join('=')], [])
      .join('&');
    return [[embedUrlBase, this.vid].join('/'), query].join('?');
  }
  get thumbUrl() {
    return [thumbUrlBase, this.vid, `${this.thumbQuality}default.jpg`].join('/');
  }
  get thumbStyle() {
    return {
      ...this.contentStyle,
      background: 'rgba(0, 0, 0, 0.6) no-repeat center center',
      'background-size': 'cover',
      cursor: 'pointer',
      overflow: 'hidden',
      display: 'inline-block',
      'background-image': `url(${this.thumbUrl})`,
    };
  }
}
