<route>
{
  "path": "/"
}
</route>
<template>
  <main>
    <Hero />
    <Features />
    <Action />
  </main>
</template>

<script>
// @ is an alias to /src
import Hero from '@/modules/marketing/MarketingHero.vue';
import Features from '@/modules/marketing/MarketingFeature.vue';
import Action from '@/modules/marketing/MarketingAction.vue';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: { Hero, Features, Action },
  layout: 'default',
  metaInfo: {
    title: 'Digital Check-in and Digital Indemnity',
    // override the parent template and just use the above title only
  },
})
export default class HomeView extends Vue {}
</script>
